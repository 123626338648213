export function i18n(key, ...params) {
  let config = I18N.LOCAL_MAP || '{}';
  config = typeof config === 'string' ? JSON.parse(config) : config;
  let v = config[key];
  v = v === undefined || v === '' ? key : v;

  if (params && params.length > 0) {
    params.forEach((param, index) => {
      v = v.replace(`{${index}}`, param);
    });
  }

  return v;
}

export function setLocalMap(map) {
  I18N.LOCAL_MAP = map;
}

export default class I18N {}

I18N.LOCAL_MAP = {};

I18N.EXPIRATION_DATE = 'vip.pay.expiration.date';
I18N.ACCESS_ERROR = 'vip.pay.access.error';
I18N.NETWORK_ERROE = 'vip.pay.network.error';
I18N.DOCUMENT_TITLE = 'vip.pay.ducoment.title';
I18N.OPEN_PRIVILEGE_CONTRAST = 'vip.pay.privilege.contrast.open';
I18N.CLOSE_PRIVILEGE_CONTRAST = 'vip.pay.privilege.contrast.close';
I18N.POINT_EXCHAGE = 'vip.pay.point.exchange';
I18N.PRIVILEGE = 'vip.pay.privilege';
I18N.POINT = 'vip.pay.point';
I18N.PURCHASE_DURATION = 'vip.pay.duration';
I18N.PAGE_NOT_EXIST = 'vip.pay.page.not.exist';
I18N.EXPIRED_AT = 'vip.pay.expired.at';
I18N.NOT_MEMBER = 'vip.pay.not.member';
I18N.PRODUCT_POINT = 'vip.pay.product.point';
I18N.PRIVILEGE_CONTRAST_ITEM = 'vip.pay.privilege.contrast.item';
I18N.PRIVILEGE_CONTRAST_MEMBER = 'vip.pay.privilege.contrast.member';
I18N.PRIVILEGE_CONTRAST_FREE = 'vip.pay.privilege.contrast.free';
I18N.PRIVILEGE_DIALOG_ITEM = 'vip.pay.privilege.dialog.item';
I18N.RENEWAL_MEMBER = 'vip.pay.renewal';
I18N.OPEN_MEMBER = 'vip.pay.open';
I18N.MORE = 'vip.pay.more';
I18N.ZH = 'zh_CN';
I18N.EN = 'en_US';
I18N.DIALOG_TIP = 'vip.pay.dialog.tip';
I18N.DIALOG_CONFIRM = 'vip.pay.dialog.confirm';
I18N.DIALOG_CANCEL = 'vip.pay.dialog.cancel';
I18N.NO_DATA = 'vip.pay.no.data';
I18N.POINT_EXCHANGE_TIP = 'vip.pay.point.exchange.tip';
I18N.PAY_SUCCESS = 'vip.pay.success';
I18N.SCAN_CODE = 'vip.pay.scan.qrCode';
I18N.CODE_INVALID = 'vip.pay.qrCode.invalid';
I18N.OFFERS = 'vip.pay.offers';
I18N.AMOUNT = 'vip.pay.amount';
I18N.MONTH = 'vip.pay.month';
I18N.PAYMENT_SCAN = 'vip.pay.payment.scan';
I18N.PAYMENT_AGREE = 'vip.pay.payment.agree';
I18N.SERVICE_AGREEMENT = 'vip.pay.service.agreement';
I18N.PAY_IMMEDIATE = 'vip.pay.immediately';
I18N.COMPANY = 'vip.pay.company';
I18N.ORDER_SUCCESS_TIP = 'vip.pay.order.success.tip';
I18N.PREFERENTIAL_PRICE = 'vip.pay.preferential.price';
I18N.PRICE = 'vip.pay.price';
I18N.PRICE_UNIT = 'vip.pay.price.unit';
I18N.BENEFICIARY = 'vip.pay.beneficiary';
I18N.DAY = 'vip.pay.day';
I18N.HOUR = 'vip.pay.hour';
I18N.MINUTE = 'vip.pay.minute';
I18N.SECOND = 'vip.pay.second';
I18N.POINT_EXCHANGE_SUCCESS = 'vip.pay.point.exchange.success';
I18N.POINT_EXCHANGE_ERROR = 'vip.pay.point.exchange.error';
